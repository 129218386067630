import { HttpClient, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

// services
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from "ng-recaptcha-2";
import { ApplicationInsightsService } from './application-insights.service';

// models
import { IAddressModel } from '../models/address-verification-models';
import { IAuthorizeSubscriptionPaymentModel } from '../models/authorize-subscription-payment.model';
import { ICountryRegion } from '../models/country-region.model';
import { ICountry } from '../models/country.model';
import { IMemberProfileModel } from '../models/member-profile-model.model';
import { IOnboardingReason } from '../models/onboarding-reason.model';
import { IOrganizationDropdown } from '../models/organization-dropdowns.model';
import { IPhoneNumber } from '../models/phone-number.model';
import { ProductCodes } from '../models/product-codes';
import { ISalesQuote, ISalesQuoteData } from '../models/sales-quote-models';
import { ISelfRegistrationModel, SelfRegistrationModel } from '../models/self-registration-model.model';
import { ISiteContent } from '../models/site-content-model';
import { ISubscriptionQuoteRequest } from '../models/subscription-quote-request.model';
import { ITimeZone } from '../models/time-zone.model';
import { IVerifyAccountOwnerProfileModel } from '../models/verify-account-owner-profile-model.model';

@Injectable({
    providedIn: 'root'
})
export class RegistrationService {

    private _registrationModel: ISelfRegistrationModel = new SelfRegistrationModel();
    private _countries!: ICountry[];
    private _regions!: ICountryRegion[];
    private _timeZones!: ITimeZone[];
    private _siteContent!: ISiteContent[];

    constructor(private readonly applicationInsightsService: ApplicationInsightsService, private readonly httpClient: HttpClient, private cookies: CookieService, private router: Router, private recaptchaV3Service: ReCaptchaV3Service) {
    }

    get accountSetupDone(): boolean {
        const value = sessionStorage?.getItem("accountSetupDone") ?? false;
        return Boolean(value) ?? false;
    }
    set accountSetupDone(value: boolean) {
        sessionStorage?.setItem("accountSetupDone", value.toString());
    }

    get countries(): Observable<ICountry[]> {
        return new Observable<ICountry[]>((observer) => {
            if (this._countries) {
                observer.next(this._countries);
                observer.complete();
            } else {
                this.httpClient.get<ICountry[]>("/api/Registration/GetCountryList").subscribe(
                    (data) => {
                        if (!data) data = [];
                        this._countries = data;
                        observer.next(this._countries);
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    },
                    () => observer.complete());
            }
        });
    }
    get regions(): Observable<ICountryRegion[]> {
        return new Observable<ICountryRegion[]>((observer) => {
            if (this._regions) {
                observer.next(this._regions);
                observer.complete();
            } else {
                this.httpClient.get<ICountryRegion[]>("/api/Registration/GetCountryRegionList").subscribe(
                    (data) => {
                        if (!data) data = [];
                        this._regions = data;
                        observer.next(this._regions);
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    },
                    () => observer.complete());
            }
        });
    }
    get timeZones(): Observable<ITimeZone[]> {
        return new Observable<ITimeZone[]>((observer) => {
            if (this._timeZones) {
                observer.next(this._timeZones);
                observer.complete();
            } else {
                this.httpClient.get<ITimeZone[]>("/api/Registration/GetTimeZones").subscribe(
                    (data) => {
                        if (!data) data = [];
                        this._timeZones = data;
                        observer.next(this._timeZones);
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    },
                    () => observer.complete());
            }
        });
    }

    get organizationDropdowns(): Observable<IOrganizationDropdown[]> {
        return this.httpClient.get<IOrganizationDropdown[]>(environment.cdn + "organization-dropdowns.json");
    }

    get onboardingReasons(): Observable<IOnboardingReason[]> {
        return this.httpClient.get<IOnboardingReason[]>(environment.cdn + "onboarding.json");
    }
    get model(): ISelfRegistrationModel { return this._registrationModel; }
    get productCode(): string { return this.getCookieValue("ProductCode", ProductCodes.elevateSubscription); }
    get currencyCode(): string { return this.getCookieValue("CurrencyCode", "USD"); }

    get siteContent(): Observable<ISiteContent[]> {
        return new Observable<ISiteContent[]>((observer) => {
            if (this._siteContent) {
                observer.next(this._siteContent);
                observer.complete();
            } else {
                this.httpClient.get<ISiteContent[]>("/api/Cms/GetSiteContent").subscribe(
                    (data) => {
                        if (!data) data = [];
                        this._siteContent = data;
                        observer.next(this._siteContent);
                        observer.complete();
                    },
                    (error) => {
                        observer.error(error);
                        observer.complete();
                    },
                    () => observer.complete());
            }
        });
    }

    hasExistingAccount(userName: string, password: string | null = null, captchaResponse: string | null = null): Observable<boolean> {

        return new Observable<boolean>((observer) => {
            
            const credentials: IVerifyAccountOwnerProfileModel = {
                userName: userName,
                password: password,
                captchaResponse: captchaResponse
            };
            
            this.getAccountOwnerProfiles(credentials).subscribe(
                (data: IMemberProfileModel[] | null) => {
                    observer.next(data === null ? false : true);
                    observer.complete();
                },
                (error) => {
                    if (error.status === 401) {
                        observer.next(true);
                    } else {
                        observer.error(error);
                    }
                    observer.complete();
                }
            );
        });
    }

    hasSalesQuote(): boolean {
        return this._registrationModel.savedQuote !== null;
    }

    generateSalesQuote(): Observable<ISalesQuote>;
    generateSalesQuote(address: IAddressModel): Observable<ISalesQuote>;
    generateSalesQuote(sourceCode: string): Observable<ISalesQuote>;
    generateSalesQuote(sourceCode: string, address: IAddressModel): Observable<ISalesQuote>;
    generateSalesQuote(sourceCode?: string | IAddressModel, address?: IAddressModel): Observable<ISalesQuote> {
        let observable: Observable<ISalesQuoteData>;
        if (typeof sourceCode === 'object') {
            address = sourceCode as IAddressModel;
            sourceCode = undefined;
        }
        if (this.model.owner.profile) {
            if (!sourceCode) { sourceCode = this.model.sourceCode; }
            const model: IAuthorizeSubscriptionPaymentModel = {
                credentials: {
                    profile: this.model.owner.profile,
                    userName: this.model.owner.emailAddress,
                    password: this.model.owner.password,
                },
                productCode: this.productCode,
                sourceCode: sourceCode ?? this.model.sourceCode,
                onboardingValue: this.model.owner.onboardingValue,
                vatRegistrationNumber: this._registrationModel.vatRegistrationNumber,
            };
            observable = this.httpClient.post<ISalesQuoteData>("/api/Registration/GenerateAuthorizationQuote", model);
        } else {
            if (!address) { address = this.model.address; }
            const request: ISubscriptionQuoteRequest = {
                address: {
                    shipToName: `${this._registrationModel.owner.firstName} ${this._registrationModel.owner.lastName}`,
                    addressLine1: address.addressLine1,
                    addressLine2: address.addressLine2,
                    addressLine3: address.addressLine3,
                    city: address.city,
                    postalCode: address.postalCode,
                    countryCode: address.countryCode,
                    stateOrRegion: address.stateOrRegion,
                },
                productCode: this.productCode,
                shopCredentials: this._registrationModel.owner.shopCredentials,
                sourceCode: typeof sourceCode !== "undefined" ? sourceCode : null,
                vatRegistrationNumber: typeof (this._registrationModel.vatRegistrationNumber) !== "undefined"
                    ? this._registrationModel.vatRegistrationNumber
                    : null
            };
            observable = this.httpClient.post<ISalesQuoteData>("/api/Registration/GenerateRegistrationQuote", request);
        }

        return observable.pipe(
            map((data) => {
                for (const line of data.quote.lineItems) {
                    line.productName = data.names[line.productCode];
                }
                return data.quote;
            }),
            catchError((err) => throwError(err))
        );
    }
    getAccountOwnerProfiles(credentials: IVerifyAccountOwnerProfileModel): Observable<IMemberProfileModel[] | null> {
        return this.httpClient
            .post<IMemberProfileModel[] | null>("/api/Registration/GetAccountOwnerProfiles", credentials, { observe: 'response' })
            .pipe(
                map(response => {
                    let value: IMemberProfileModel[] | null = null;
                    switch (response.status) {
                        case 200: { value = response.body; } break;
                        case 204: { value = null; } break;
                        default: { this.handleUnexpectedStatus(response); } break;
                    }
                    return value;
                }),
                catchError(err => throwError(err)),
            );
    }
    getLegacyWebAccountData(credentials: IVerifyAccountOwnerProfileModel): Observable<boolean> {
        return this.httpClient
            .post<ISelfRegistrationModel | null>("/api/Registration/GetLegacyWebAccountData", credentials, { observe: 'response' })
            .pipe(
                map(response => {
                    switch (response.status) {
                        case 200: {
                            // the data we get back for the shop account is represented using a
                            // SelfRegistrationModel, however not all of the property values are
                            // available from the Oracle data. therefore, we need to remove any
                            // properties that are set to null so that we don't accidentially erase
                            // any values from our local model that have been collected from the user.
                            this.patchModel(response.body)
                            this._registrationModel.owner.shopCredentials = credentials;
                            return true;
                        }
                        case 204: { return false; }
                    }
                    this.handleUnexpectedStatus(response);
                    return false;
                }),
                catchError(err => throwError(err)),
            );
    }
    patchModel(data: any) {
        if (typeof data === 'object') {
            this.patchModelValues(data, this._registrationModel);
        }
    }
    loadRegistrationData() {
        const json = sessionStorage?.getItem("registrationData");
        if (json) { this.patchModel(JSON.parse(json)); }
        this.saveRegistrationData();
    }
    register(): Observable<void> {
        let observable!: Observable<HttpResponse<{ location: string }>>;
        if (this.model.owner.profile) {
            const model: IAuthorizeSubscriptionPaymentModel = {
                credentials: {
                    password: this.model.owner.password,
                    profile: this.model.owner.profile,
                    userName: this.model.owner.emailAddress,
                },
                productCode: this.productCode,
                salesQuoteId: this.model.salesQuoteId,
                sourceCode: this.model.sourceCode,
                onboardingValue: this.model.owner.onboardingValue
            };
            observable = this.httpClient.post<{ location: string }>("/Subscription/AuthorizeSubscription", model, { observe: 'response' });
        } else {

            const $model = this.getServerRegistrationModel();

            observable = $model.pipe(
                switchMap(model => this.httpClient.post<{ location: string }>("/Subscription/Register", model, { observe: 'response' }))
            );
        }

        return observable.pipe(
            tap((response) => {
                const content = response.body;
                if (content?.location) {
                    window.location.href = content.location;
                };
            }),
            map(() => { }),
            catchError((err) => throwError(err))
        );
    }
    saveRegistrationData(model?: any): boolean {
        if (!this._registrationModel.productCode) {
            this._registrationModel.productCode = this.productCode;
        }
        if (typeof model === 'object') {
            this.patchModel(model);
        }
        if (sessionStorage) {
            const json = JSON.stringify(this._registrationModel);
            sessionStorage.setItem("registrationData", json);
            return true;
        }
        return false;
    }
    resetRegistrationData() {
        this._registrationModel = new SelfRegistrationModel();
        sessionStorage?.removeItem("registrationData");
        sessionStorage?.removeItem("accountSetupDone");
    }
    validateAddress(addressModel: IAddressModel): Observable<IAddressModel | null> {
        return this.httpClient.post<IAddressModel>(`${environment.webApiUrl}/api/address/validate`, addressModel, { observe: 'response' })
            .pipe(
                map((response) => {
                    let value: IAddressModel | null = null;
                    switch (response.status) {
                        case 200: { value = response.body; } break;
                        case 204: { value = null; } break;
                        default: { this.handleUnexpectedStatus(response); } break;
                    }
                    return value;
                }),
                catchError(err => throwError(err)),
            );
    }

    private getCookieValue(name: string, defaultValue: string) {
        let value = "";
        if (name && this.cookies.check(name)) {
            value = this.cookies.get(name);
        }
        if (!value) {
            value = defaultValue;
        }
        return value;
    }

    private getServerRegistrationModel(): Observable<ISelfRegistrationModel> {

        const $token = (environment.reCaptchaOn)
            ? this.recaptchaV3Service.execute("subscription_register")
            : of("");

        return $token.pipe(
            map(token => {
                const model = new SelfRegistrationModel();
                this.patchModelValues(this._registrationModel, model, false);
                this.patchBooleanYN(model.owner, ['allowEmailCommunication', 'isConsultant']);
                this.patchPhoneNumber(model, ['phoneNumber']);
                this.patchPhoneNumber(model.owner, ['phoneNumber']);
                model.owner.emailAddress = model.owner.emailAddress.toLowerCase();
                model.owner.userName = model.owner.emailAddress;
                model.captchaResponse = token;
                return model;
            })
        );
    }

    private handleUnexpectedStatus(response: HttpResponseBase) {
        if (typeof this.applicationInsightsService !== 'undefined') {
            const error = new Error(`Unexpected status '${response.status}' returned from '${response.url}'`)
            this.applicationInsightsService.logException(error, SeverityLevel.Warning);
        }
    }
    private patchModelValues(source: any, target: any, copyMissing = true) {
        if (source === null) { return; }
        Object.keys(source).forEach(name => {
            if (typeof target[name] === 'object') {
                if (!target[name] && source[name] && copyMissing) {
                    target[name] = Object.assign({}, source[name]);
                } else if (target[name]) {
                    this.patchModelValues(source[name], target[name]);
                } else if (source[name] !== null && typeof source[name] !== 'undefined') {
                    target[name] = source[name];
                }
            } else if (source[name] !== null && typeof source[name] !== 'undefined') {
                target[name] = source[name];
            }
        })
    }
    private patchPhoneNumber(model: any, propList: string[]) {
        for (const name of propList) {
            if ((model[name] as IPhoneNumber).e164Number) {
                model[name] = (model[name] as IPhoneNumber).e164Number
            } else if (typeof model[name] === 'string') {
                model[name] = (model[name] as string);
            } else { delete model[name] }
        }
    }
    private patchBooleanYN(model: any, propList: string[]) {
        for (const name of propList) {
            let value: any = model[name];
            if (typeof value === 'string') {
                value = (value === 'Y' || value === 'y') ? true : false;
            } else if (typeof value === 'boolean') {
                value = (value as boolean);
            }
            model[name] = value;
        }
    }
    redirectTo(uri: string) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate([uri]));
    }
}
