<div *ngIf="!initializationError" style="display: grid; grid-template-rows: auto 1fr auto auto; min-height: 100vh">
  <site-header></site-header>
  <div class="container-fluid pad px-1 px-md-3">
    <div [hidden]="infoLoaded || initializationError" class="spinner-container">
      <div class="d-flex justify-content-center">
        <div class="spinner-border custom-spinner" role="status">
          <span class="sr-only" i18n>Loading...</span>
        </div>
      </div>
    </div>
    <div>
      <alert></alert>
    </div>
    <router-outlet *ngIf="infoLoaded && !initializationError" (activate)="routeChanged()"></router-outlet>
  </div>
  <div>&nbsp;</div>
  <site-footer></site-footer>
</div>

<div *ngIf="initializationError">
  <site-header></site-header>
  <br />
  <app-error></app-error>
  <site-footer></site-footer>
</div>
