import { Routes, UrlSegment } from "@angular/router";

/*
 * This checks to see if the url is appended with a target (ex. https://certificaton.themyersbriggs.com/?target=%2Faccount-management)
 * If so, it removes everything up to and including ?target=%2F, saves it as "segment" and takes the user to that route
 * In the event that there is no route (ex. https://certificaton.themyersbriggs.com/?target=%2F) it takes the user
 * to the defaultRoute (which we can set to whatever we want)
 * In the event that the target is an invalid route (ex. https://certificaton.themyersbriggs.com/?target=%2Fabc)
 * it takes the user to the component listed in the Routes array (ex. { matcher: checkTarget, component: ProgramOverviewComponent })
 *
 * Update: if we are passing a parameter, (ex. u=1) the url wil look like this: /?target=%2Faccount-management%3Fu=1
 * Then we are creating a KVP for { u: 1 } and passing it into the "parameters" parameter of the UrlSegment
 * It gets translated into a matrix parameter, so the resulting url is https://certificaton.themyersbriggs.com/#/account-management;u=1
 * Note: Currently we don't have the functionality to handle more than one query parameter in the /target link
 */
export function checkTarget(url: UrlSegment[]) {
    if (location.href.includes("?target=")) {
        const defaultRoute = "certification";

        let segment = location.href.split("?target=%2F").pop() ?? "";

        // Parse querystring into matrix parameter
        if (segment.indexOf("%3F") > -1) {
            const querystring = segment.split('%3F').pop() ?? "";
            const key: string = querystring.split('=')[0];
            const value = querystring.split('=')[1];
            const parameters = { [key]: value };

            segment = segment.length ? segment : defaultRoute;
            segment = segment.split('%3F')[0] ?? "";
            url = [new UrlSegment(segment, parameters)];
        } else {
            segment = segment.length ? segment : defaultRoute;
            url = [new UrlSegment(segment, {})];
        }

        return { consumed: url };
    }
    return null;
}


export const routes: Routes = [
    {
        path: 'error',
        loadComponent: () => import('./error/error.component').then(m => m.ErrorComponent)

    },
    {
        matcher: checkTarget,
        loadComponent: () => import('./registration/registration.component').then(m => m.RegistrationComponent)

    },
    {
        path: '',
        loadComponent: () => import('./registration/account/account.component').then(m => m.AccountComponent)
    },
    {
        path: 'account',
        children: [
            {
                path: 'details',
                loadComponent: () => import('./registration/account/account.component').then(m => m.AccountComponent)
            },
            {
                path: 'security',
                loadComponent: () => import('./registration/security/security.component').then(m => m.SecurityComponent)
            },
            {
                path: 'organization',
                loadComponent: () => import('./registration/organization/organization.component').then(m => m.OrganizationComponent)
            },
            {
                path: 'review/:err',
                loadComponent: () => import('./registration/review/review.component').then(m => m.ReviewComponent)
            },
            {
                path: 'review',
                loadComponent: () => import('./registration/review/review.component').then(m => m.ReviewComponent)
            },
            {
                path: 'confirmation/sgc',
                loadComponent: () => import('./registration/sgc-confirmation/sgc-confirmation.component').then(m => m.SgcConfirmationComponent)
            },
            {
                path: 'confirmation/limited',
                loadComponent: () => import('./registration/confirmation/confirmation.component').then(m => m.ConfirmationComponent)
            },
            {
                path: 'confirmation',
                loadComponent: () => import('./registration/confirmation/confirmation.component').then(m => m.ConfirmationComponent)
            }
        ]
    },
    {
        path: 'MBTICertification/account',
        children: [
            {
                path: 'details',
                loadComponent: () => import('./registration/account/account.component').then(m => m.AccountComponent)
            },
            {
                path: 'security',
                loadComponent: () => import('./registration/security/security.component').then(m => m.SecurityComponent)
            },
            {
                path: 'organization',
                loadComponent: () => import('./registration/organization/organization.component').then(m => m.OrganizationComponent)
            },
            {
                path: 'review/:err',
                loadComponent: () => import('./registration/review/review.component').then(m => m.ReviewComponent)
            },
            {
                path: 'review',
                loadComponent: () => import('./registration/review/review.component').then(m => m.ReviewComponent)
            },
            {
                path: 'confirmation/sgc',
                loadComponent: () => import('./registration/sgc-confirmation/sgc-confirmation.component').then(m => m.SgcConfirmationComponent)
            },
            {
                path: 'confirmation',
                loadComponent: () => import('./registration/confirmation/confirmation.component').then(m => m.ConfirmationComponent)
            }]
    },

    {
        path: '**',
        loadComponent: () => import('./error/not-found.component').then(m => m.NotFoundComponent)
    }
];