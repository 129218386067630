import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalUrls } from '../../common/constants/external-urls';

@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
  standalone: true,
})
export class SiteHeaderComponent implements OnInit {
  name = '';
  deactivateLinks = true;
  deactivateCertification = false;
  contactUs = ExternalUrls.ContactUs;

  constructor(public router: Router) {}

  ngOnInit() {}

  contactClick() {
    window.open(this.contactUs, '_blank');
  }
}
