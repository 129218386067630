import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import '@angular/localize/init';
import { Title } from '@angular/platform-browser';
import { Router, RouterOutlet } from '@angular/router';
import * as bootstrap from 'bootstrap';
import { ProductCodes } from './common/models/product-codes';
import { GoogleAnalyticsService } from './common/services/google-analytics.service';
import { RegistrationService } from './common/services/registration.service';
import { imports } from './types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [...imports, CommonModule, RouterOutlet],
})
export class AppComponent implements OnInit {
  title = 'Registration';
  infoLoaded = true;
  initializationError = false;
  private _bootstrap = bootstrap;

  constructor(
    private titleService: Title,
    private router: Router,
    private location: Location,
    private googleAnalyticsService: GoogleAnalyticsService,
    private registrationService: RegistrationService
  ) {}

  ngOnInit() {
    this.googleAnalyticsService.init();
    this.titleService.setTitle(this.title);
  }

  routeChanged() {
    // Check URL and product code cookie so we can modify the URL or redirect the user as needed
    const currentRoute = this.router.url;
    const productCode = this.registrationService.productCode;

    if (currentRoute.startsWith('/account') && productCode === ProductCodes.selfGuidedCertification) {
      this.location.replaceState('/MBTICertification' + currentRoute);
    }

    if (currentRoute.startsWith('/MBTICertification') && productCode !== ProductCodes.selfGuidedCertification) {
      window.location.href = '/Subscription/MBTICertification';
    }

    if (currentRoute === '/' && productCode === ProductCodes.selfGuidedCertification) {
      this.location.replaceState('/MBTICertification/account/details');
    }
  }
}
