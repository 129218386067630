import { IRegistrationContent } from '../models/registration-content.model';

export const RegistrationContent: IRegistrationContent[] = [
    {
        account: {  // Elevate registration.
            header: $localize`Registration`,
            subHeader: $localize`Let's create your Elevate<sup class="reg sub-header">&reg;</sup>&nbsp;account.`,
            body: null
        },
        security: {
            header: $localize`Registration`,
            subHeader: $localize`Help us keep your account safe.`,
            body: null
        },
        organization: {
            header: $localize`Registration`,
            subHeader: $localize`Tell us about your organization.`,
            body: null
        },
        review: {
            header: $localize`Registration`,
            subHeader: $localize`Everything you need in one license`,
            body: null
        },
        confirmation: {
            header: $localize`Registration`,
            subHeader: null,
            body: $localize`<div>
                    <div class="section-subheader-redesign my-4">You have successfully registered for Elevate.</div>
                    <div class="content" style="background-color: white">
                        Thank you for registering on the Elevate platform. You will be emailed a receipt with your purchase details once your order has been processed.
                        <br /><br />
                        Click the<strong> Continue to Log In </strong>button to log in to your Elevate account and begin sending assessments, purchasing inventory, and generating reports.
                        <br /><br />
                        <em>Note: </em>Don't forget to bookmark elevate.themyersbriggs.com. This is where you will access your account.
                        <br /><br />
                        If you have any questions, our Customer Support team is available to help you, please <a href='https://login.themyersbriggs.com/support/contact.aspx'>contact us</a> for assistance.
                    </div>
                </div>`
        }
    },
    {
        account: { // Self guided certification.
            header: $localize`Register for certification`,
            subHeader: $localize`Let's start your MBTI<sup class="reg sub-header">&reg;</sup>&nbsp;certification journey.`,
            body: null
        },
        security: {
            header: $localize`Register for certification`,
            subHeader: $localize`Help us keep your account safe.`,
            body: null
        },
        organization: {
            header: $localize`Register for certification`,
            subHeader: $localize`Tell us about your organization.`,
            body: null
        },
        review: {
            header: $localize`Register for certification`,
            subHeader: $localize`Review your order.`,
            body: null
        },
        confirmation: {
            header: $localize`Register for certification`,
            subHeader: null,
            body: $localize`<div>
                    <div class="section-subheader-redesign my-4">You're registered for MBTI<sup class="reg sub-header">&reg;</sup>&nbsp;certification!</div>
                    <div class="content" style="background-color: white">
                        Thank you for purchasing the Self-Guided MBTI<sup class="reg">&reg;</sup>&nbsp;Certification Program. You'll receive a receipt by email as soon as we've processed your order.
                        <br /><br />
                        Click the "Continue to log in" button to get started with your certification. You'll have access to the program for 90 days from today.
                        <br /><br />
                        Don't forget to bookmark elevate.themyersbriggs.com so that you can easily come back and access your online learning.
                        <br />
                        Any questions? Our Customer Support team is available to help you, please <a href='https://login.themyersbriggs.com/support/contact.aspx'>contact us</a> for assistance.
                    </div>
                </div>`
        }
    },
    {
        account: { // Limited access.
            header: $localize`Registration`,
            subHeader: $localize`Let’s create your account.`,
            body: null
        },
        security: {
            header: $localize`Registration`,
            subHeader: $localize`Help us keep your account safe.`,
            body: null
        },
        organization: {
            header: $localize`Registration`,
            subHeader: $localize`Tell us about your organization.`,
            body: null
        },
        review: {
            header: "",
            subHeader: null,
            body: null
        },
        confirmation: {
            header: $localize`Registration`,
            subHeader: null,
            body: $localize`<div>
                    <div class="section-subheader-redesign my-4">Congratulations!</div>
                    <div class="content" style="background-color: white">
                        You have created your account on the Elevate® assessment website. A confirmation email has been sent to you.
                        <br /><br />
                        Continue to log in below and complete your purchase.
                    </div>
                </div>`
        }
    }
];