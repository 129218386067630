import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withHashLocation, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { RECAPTCHA_LOADER_OPTIONS, RECAPTCHA_V3_SITE_KEY, RecaptchaLoaderService, ReCaptchaV3Service } from 'ng-recaptcha-2';
import { environment } from 'src/environments/environment';
import { routes } from './app.routes';
import { AppService } from './app.service';
import { requestedWithInterceptor } from './common/http-interceptors/requested-with-interceptor';
import { ApplicationInsightsService } from './common/services/application-insights.service';
import { ContentService } from './common/services/content.service';
import { DataService } from './common/services/data.service';
import { ExternalScriptsService } from './common/services/external-scripts.service';
import { GoogleAnalyticsService } from './common/services/google-analytics.service';
import { RegistrationService } from './common/services/registration.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withRouterConfig({ onSameUrlNavigation: 'reload' }),
      withHashLocation()
    ),
    provideAnimations(),
    provideHttpClient(withInterceptors([requestedWithInterceptor])),
    { provide: Window, useValue: window },
    importProvidersFrom(
      Angular2PromiseButtonModule.forRoot({
        // your custom config goes here
        spinnerTpl: '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>',
        // disable buttons when promise is pending
        disableBtn: true,
        // the class used to indicate a pending promise
        btnLoadingClass: 'is-loading',
        // only disable and show is-loading class for clicked button,
        // even when they share the same promise
        handleCurrentBtnOnly: false,
      })
    ),
    ExternalScriptsService,
    AppService,
    ApplicationInsightsService,
    GoogleAnalyticsService,
    DataService,
    {
      provide: APP_INITIALIZER,
      useFactory: (contentService: ContentService) => () => contentService.initializeConfiguration(),
      multi: true,
      deps: [ContentService],
    },
    ContentService,
    ReCaptchaV3Service,
    RecaptchaLoaderService,
    RegistrationService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.reCaptchaOn ? environment.reCaptchaSiteKey : null,
    },
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useValue: {
        onBeforeLoad() {
          return {
            url: new URL('https://recaptcha.net/recaptcha/api.js'),
          };
        },
      },
    },
  ],
};
